import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import ErrorBoundary from './ErrorHandler';
import { setupServiceWorker } from './service_worker/setupServiceWorker';
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Auth0ProviderWithNavigate } from "./auth0-provider-with-navigate";
import "./styles/styles.css";
import { ModalProvider } from './components/ModalContext/ModalContext';
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';
import { UserSettingsProvider } from './components/UserSettingsProvider/UserSettingsProvider';

const appRoot = (process.env.NODE_ENV==="development") ? process.env.REACT_APP_API_BASE_URL_DEV : process.env.REACT_APP_API_BASE_URL;
const appOrigin = (process.env.NODE_ENV==="development") ? process.env.REACT_APP_ORIGIN_DEV : process.env.REACT_APP_ORIGIN;
const appTitle = (process.env.NODE_ENV==="development") ? process.env.REACT_APP_TITLE_DEV : process.env.REACT_APP_TITLE;
const appDescription = (process.env.NODE_ENV==="development") ? process.env.REACT_APP_DESCRIPTION_DEV : process.env.REACT_APP_DESCRIPTION;

  axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept';
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = "*";
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = appOrigin;
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = appRoot;
  axios.defaults.headers.post['Accepts'] = 'application/json; charset=UTF-8; text/plain; */*';
  axios.defaults.baseURL = appRoot;

  axios.interceptors.response.use(
    response => response,
    error => { 
      console.log("ResponseERRR", error);
      if ((error.code)&&(error.code==="ERR_NETWORK")) console.log("NETWORK ERROR")
      return Promise.reject(getUIMessage(error)); }
  );




/*
  console.log("Environment", process.env.NODE_ENV);
  console.log("Title", appTitle);
  console.log("Description", appDescription);
  console.log("API ROOT", appRoot);
  console.log("API ENVIRONMENT", appOrigin);
*/

function translationErrorMessage(message){
  console.log("MESSAGESZZZ", message);
  if (message === "ManagementApiError: PasswordStrengthError: Password is too weak") return "Password is too week.";
  return message;
}

function getUIMessage(error){
  console.log("ERRRROROROR", error);
  if (error.code == "ERR_NETWORK"){
    if (error.config.url === "/auth/login/") {
      return "Connect has been unable to authenticate you. Please contact Arquella to report this issue.";
    }
    else { 
      return "Connection error.  You are either offline or there is a problem connecting to the server."; 
    }
  }
  else {
    try {
      console.log("API Error", error.response)
      const val = error.response.data.message;
      if (val!==undefined) return translationErrorMessage(val);

      const val2 = error.response.data.msg;
      if (val2!==undefined) return translationErrorMessage(val2);

      return error.response.data;
    }
    catch (e){
      console.log("eeeeeebbb", e);
    }
    return error.toString();
  }
}

setupServiceWorker();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
          <ModalProvider>
            <SnackbarProvider maxSnack={3}>
              <UserSettingsProvider>
                <App />
              </UserSettingsProvider>
            </SnackbarProvider>
          </ModalProvider>
    </BrowserRouter>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();






